<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      @getList="getList"
      @clearSearch="clearSearch"
    >
      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.nickname"
          size="small"
          clearable
          placeholder="按微信名查询"
        ></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select
                v-model="searchFormData.status"
                placeholder="按同步状态搜索"
                clearable
                size="small"
        >
          <el-option
                  label="已同步"
                  :value="10"
          >
          </el-option>
          <el-option
                  label="未同步"
                  :value="20"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!--表格内容-->
      <vxe-table-column slot="table-item" title="用户信息" align="center" min-width="80px" class="vxe-cell">
        <template v-slot="{ row }">
          <span>{{row.nickname}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
              slot="table-item"
              field="balance"
              title="老平台钱包余额"
              align="center"
              min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        field="msg"
        title="返回信息"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
              slot="table-item"
              field="refund_status"
              title="同步状态"
              align="center"
              min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.status[row.status]||'未知状态'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="create_time"
        title="同步时间"
        align="center"
        min-width="100px"
      />
    </Tables>
  </div>
</template>
<script>
import Tables from '@/components/tables'
export default {
  name: 'user-refund-list',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '余额同步记录列表',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {
        u_type: 10,
        status: 10
      },
      types: {
        status: {
          10: '已同步',
          20: '未同步'
        }
      },
      refundDialogShow: false
    }
  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await this.$api.Fans.GetOldBalance(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 关闭弹窗
    closeDialog() {
      this.detailDialogShow = false
      this.refundDialogShow = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {
        u_type: 10
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
